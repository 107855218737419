<template>
  <div class="message">
   <van-nav-bar left-arrow @click-left="onClickLeft">
     <template #title>
       <div class="top-nav df aic jcsb">
         <div class="df aic jcc">
           <span style="font-weight: bold; font-size: 20px; line-height: normal">公告中心</span>
         </div>
       </div>
     </template>
   </van-nav-bar>
    <van-cell v-for="item in list" :key="item.id" @click="messageDetails(item)" class="mb40 mt10"
      style="padding-bottom:20px">
      <div style="font-weight: bold;font-size: 16px;margin-top: 5px;">{{ item.title }}</div>
      <div style="margin-top: 10px;">时间：{{ item.createTime }}</div>
      <div class="df" >
       <!-- <img :src="item.main_image1"   style="height: 100px;width: 100px;" /> -->
        <div style="font-size: 12px;" >&nbsp;&nbsp;&nbsp; {{ item.text_title }}</div>
      </div>
    <!--  <div v-html="change(item.content)" class="pstysd fz16 van-multi-ellipsis--l3 mt10"></div> -->
    </van-cell>
  </div>
</template>
<script>
import { getMessageList } from '@/utils/api.js'
export default {
  name: 'message',
  data() {
    return {
      list: []
    }
  },
  created() {
    this.initData();
  },
  methods: {
   initData() {
     let params = {
       type : 2
     }
     getMessageList(params).then((res) => {
       // console.log(res, '111');
       this.list = res.data
     })
   },
    onClickLeft() {
      this.$router.go(-1)
    },
    change(strValue) {
      // console.log(strValue,'1');
      strValue = strValue.replace(/&amp;/g, "&");
      strValue = strValue.replace(/&lt;/g, "<");
      strValue = strValue.replace(/&gt;/g, ">");
      strValue = strValue.replace(/&quot;/g, "\"");
      strValue = strValue.replace(/&#39;/g, "'");
      // console.log(strValue,'2');
      return strValue;
    },
    messageDetails(item) {
      this.$router.push({
        name: 'messageDetails',
        query: {
          items: JSON.stringify(item)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .van-nav-bar__arrow {
      font-size: 46px !important;
      padding-top: 10px;
      color: #000;
  }
::v-deep .van-nav-bar__title {
  padding-top: 10px !important;
  font-size: 20px;
   color: #000;
}
::v-deep(.van-nav-bar__left) {
  font-size: 32px !important;
  font-weight: bold;
}

.message {
  background: #fff;
  font-size: 14px;
  min-height: 100%;
  padding: 40px 30px;
  font-family: "pingfang" !important;


  ::v-deep(.van-nav-bar__title) {
    font-weight: bold !important;
    font-size: 30px !important;
    height: 60px;
  }

  ::v-deep(.van-nav-bar__content) {
    height: 60px;
    margin: auto;
  }

  .pstysd {

    // overflow: hidden;
    // display: -webkit-box;
    // -webkit-box-orient: vertical;
    // -webkit-line-clamp: 3;
    // height: 200px;
    img {
      width: 100%;
    }
  }
}
</style>
